import { useCallback, useMemo } from 'react';
import Fuse from 'fuse.js';

interface UseSearchListProps<T> {
  list: T[];
  searchQuery: string;
}

export const useSearchList = <T>({ list, searchQuery }: UseSearchListProps<T>) => {
  const fuse = useMemo(
    () =>
      new Fuse(list, {
        includeMatches: true,
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
        keys: ['searchKeys'],
      }),
    [list]
  );

  const getGlobaleSearchList = useCallback(() => {
    if (searchQuery === '') return list;

    // Perform the Fuse.js search to get matching items.
    const matchedItems = fuse.search(searchQuery).map((item) => item.item);

    // Create a Set to avoid duplicates.
    const matchedSet = new Set(matchedItems);

    // Filter the original list to include only the unmatched items.
    const unmatchedItems = list.filter((item) => !matchedSet.has(item));

    // Concatenate the matched items with the unmatched items.
    return [...matchedItems, ...unmatchedItems];
  }, [fuse, list, searchQuery]);

  const getSearchList = useCallback(() => {
    if (searchQuery.length === 0) return list;
    return getGlobaleSearchList();
  }, [getGlobaleSearchList, list, searchQuery]);

  const searchList = getSearchList();

  return { searchList };
};

export default useSearchList;
