import { AuthConfig, TestAuthProvider } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import { AuthConfirmTriggerType, AuthContext, AuthFrame } from '@sqior/react/uiauth';
import { KeycloakConfirmProvider, OAuthWrapper, PublicAuthProvider } from '@sqior/react/uiauthkc';
import { MainFrame } from '@sqior/react/uibase';
import { VersionInfo } from '@sqior/react/utils';
import CoreServices, { CoreServicesConfig } from '../core-services/core-services';
import { QrPairingPage } from '@sqior/react/uiqrscanner';
import { PublicCoreServices } from '../core-services/public-core-services';
import { AnonymousToken } from '@sqior/viewmodels/app';
import React, { useState } from 'react';
import { PublicAuthWrapper } from '../core-services/public-auth-wrapper';

export enum AuthenticationSystem {
  None,
  Auth0,
  OAuth,
  KeycloakConfirm,
  QRCode,
}

export type AppConfig = {
  core: CoreServicesConfig;
  auth: AuthenticationSystem;
  authScopes?: string[];
  authConfig?: AuthConfig
};

export interface AppFrameProps {
  children: React.ReactNode;
  config: AppConfig;
  version: VersionInfo;
}

const keycloakConfirmProvider = new KeycloakConfirmProvider();

export function AppFrame(props: AppFrameProps) {
  /* Check if a test user is specified in the URL */
  const params = new URLSearchParams(window.location.search);
  const storage = window.localStorage ?? window.sessionStorage;

  const [token, setToken] = useState(storage.getItem('pairingToken') ?? '');

  const handleTokenChange = (token: string) => {
    storage.setItem('pairingToken', token);
    setToken(token);
  };

  const testUser = params.get('testUser');
  const kiosk = params.get('kiosk');
  const kioskToken = params.get('token');

  /* Do not instantiate auth0 if window.crypto is not available which is the case with jsdom */
  if (window.crypto && props.config.auth === AuthenticationSystem.Auth0)
    return <h1>Auth0 is disabled!</h1>;
  /*
    return (
      <AuthWrapper>
        <MainFrame>
          <AuthFrame>
            <CoreServices config={props.config.core}>
              {props.children}
            </CoreServices>
          </AuthFrame>
        </MainFrame>
      </AuthWrapper>
    );
  */ else if (testUser || (kiosk && kioskToken))
    return (
      <AuthContext.Provider
        value={{
          provider: new TestAuthProvider(
            testUser ? 'test-' + testUser : 'kiosk-' + kiosk + '-' + kioskToken
          ),
          confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
        }}
      >
        <MainFrame>
          <CoreServices config={props.config.core} version={props.version}>
            {props.children}
          </CoreServices>
        </MainFrame>
      </AuthContext.Provider>
    );
  else if (props.config.auth === AuthenticationSystem.OAuth)
    return (
      <OAuthWrapper scopes={props.config.authScopes} authConfig={props.config.authConfig}>
        <MainFrame>
          <AuthFrame>
            <CoreServices config={props.config.core} version={props.version}>
              {props.children}
            </CoreServices>
          </AuthFrame>
        </MainFrame>
      </OAuthWrapper>
    );
  else if (props.config.auth === AuthenticationSystem.KeycloakConfirm)
    return (
      <OAuthWrapper authProvider={keycloakConfirmProvider} scopes={props.config.authScopes}>
        <div />
      </OAuthWrapper>
    );
  else if (props.config.auth === AuthenticationSystem.QRCode) {
    if (token)
      return (
        <AuthContext.Provider
          value={{
            provider: new PublicAuthProvider(token),
            confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
          }}
        >
          <MainFrame>
            <AuthFrame>
              <CoreServices config={props.config.core} version={props.version}>
                <PublicAuthWrapper token={token}> {props.children}</PublicAuthWrapper>
              </CoreServices>
            </AuthFrame>
          </MainFrame>
        </AuthContext.Provider>
      );
    else {
      return (
        <AuthContext.Provider
          value={{
            provider: new PublicAuthProvider(AnonymousToken),
            confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
          }}
        >
          <MainFrame>
            {
              <PublicCoreServices config={props.config.core} version={props.version}>
                <QrPairingPage tokenCallback={handleTokenChange} />
              </PublicCoreServices>
            }
          </MainFrame>
        </AuthContext.Provider>
      );
    }
  } else
    return (
      <MainFrame>
        <CoreServices config={props.config.core} version={props.version}>
          {props.children}
        </CoreServices>
      </MainFrame>
    );
}

export default AppFrame;
