import { ComponentFactory } from '@sqior/react/factory';
import { useDynamicState, useUIGlobalState } from '@sqior/react/state';
import { ContainerWithShadow, SearchInput } from '@sqior/react/uibase';
import { ListViewItem } from '@sqior/viewmodels/visual';
import { useContext } from 'react';
import styles from './dashboard-list.module.css';
import { AnimatePresence } from 'framer-motion';
import { useIsMobile, useSearchList } from '@sqior/react/hooks';

export type DashboardListLocation = 'old-dashboard' | undefined;

export interface DashboardListProps {
  path: string;
  className?: string;
  location?: DashboardListLocation;
}

const MINIMUM_SEARCH_ITEMS = 8;

const hasSearchInput = (list: ListViewItem[], location?: DashboardListLocation) => {
  if (location === 'old-dashboard') return false;
  return (
    list.some((item) => item?.searchKeys?.length && item.searchKeys.length > 0) &&
    list.length > MINIMUM_SEARCH_ITEMS
  );
};

const SHADOW_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
};

const CONTAINER_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
};

export function DashboardList({ path, location, className }: DashboardListProps) {
  const {
    UIGlobalState: { searchQuery },
    setUIGlobalState,
  } = useUIGlobalState();

  const isMobile = useIsMobile();

  const list = useDynamicState<ListViewItem[]>(path, []);
  const FactoryComponent = useContext(ComponentFactory);

  const { searchList } = useSearchList<ListViewItem>({ list, searchQuery });

  const hasSearch = hasSearchInput(list, location);

  const onSearchQueryChange = (value: string) => {
    setUIGlobalState((state) => ({ ...state, searchQuery: value }));
  };

  return (
    <div className={className} style={CONTAINER_STYLE}>
      {isMobile && hasSearch && <SearchInput value={searchQuery} setValue={onSearchQueryChange} />}
      <ContainerWithShadow style={SHADOW_STYLE}>
        <div className={styles['scroll-container']}>
          <AnimatePresence initial={false}>
            {searchList.map((item) => (
              <FactoryComponent data={item} key={JSON.stringify(item)} />
            ))}
          </AnimatePresence>
        </div>
      </ContainerWithShadow>
    </div>
  );
}

export default DashboardList;
