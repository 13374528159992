import { ClockTimestamp } from '@sqior/js/data';
import { EntityHeader } from '@sqior/js/entity';
import { OperationSpec } from '@sqior/js/operation';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { TableData } from './types';

export enum AnalyticViewModelTypes {
  Dashboard = 'AnalyticDashboard',
  DashboardGroup = 'AnalyticDashboardGroup',
  AnalyticsLabelCardVM = 'AnalyticsLabelCardVM',
  TimeSeriesCardVM = 'TimeSeriesCardVM',
  StackedTimeSeriesCardVM = 'StackedTimeSeriesCardVM',
  SeriesCardVM = 'SeriesCardVM',
  PieChartCardVM = 'PieChartCardVM',
  BarChartCardVM = 'BarChartCardVM',
  StackedBarChartCardVM = 'StackedBarChartCardVM',
  TimeSeriesBarChartCardVM = 'TimeSeriesBarChartCardVM',
  TimeSeriesStackedBarChartCardVM = 'TimeSeriesStackedBarChartCardVM',
  HistogramCardVM = 'HistogramCardVM',
  StatisticComparisonItemVM = 'StatisticComparisonItemVM',
  StatisticComparisonVM = 'StatisticComparisonVM',
  TableVM = 'TableVM',
  StaticCardVM = 'StaticCardVM',
  SimpleStaticCardVM = 'SimpleStaticCardVM',
}

export type ValueWithUnit = {
  value: number;
  unit?: string; // e.g. "min" or "%"
  separateUnit?: boolean; // Indicated whethe value and unit shall be separated by a &nbsp;, e.g. 10 mm, 12%
  decimals?: number; //
};
export function makeValue(value: number, unit?: string, separateUnit?: boolean) {
  return makeValueD(value, 0, unit, separateUnit);
}
export function makeValueD(value: number, decimals: number, unit?: string, separateUnit?: boolean) {
  const res: ValueWithUnit = { value };
  if (unit) res.unit = unit;
  if (separateUnit === false) res.separateUnit = separateUnit === false ? false : true;
  if (decimals) res.decimals = decimals;
  return res;
}

export enum TrendType {
  Increase = 1,
  Decrease = -1,
  Equal = 0,
  Unknown = 999,
}
export type TrendWithValue = {
  value?: ValueWithUnit;
  type: TrendType;
  tooltip?: string; // Might change to a complexer structure later
};
export function makeTrend(
  value: number,
  options?: { unit?: string; separateUnit?: boolean; tooltip?: string; decimals?: number }
): TrendWithValue {
  const res: TrendWithValue = {
    type: Number.isNaN(value)
      ? TrendType.Unknown
      : value < 0
      ? TrendType.Decrease
      : value > 0
      ? TrendType.Increase
      : TrendType.Equal,
  };
  if (Number.isFinite(value))
    res.value = makeValueD(value, options?.decimals ?? 0, options?.unit, options?.separateUnit);
  if (options?.tooltip) res.tooltip = options.tooltip;
  return res;
}

export type AnalyticsLabelCardVM = EntityHeader & {
  title: string;
  select?: OperationSpec;
  value: ValueWithUnit;
  trend?: TrendWithValue;
  description?: string;
};

export type TimeSeriesChartVM = EntityHeader & {
  title: string;

  unit: string; // Deprecated in favor of valueWithUnit
  currentValue?: number; // Deprecated in favor of valueWithUnit
  avgValue?: number; // Deprecated in favor of valueWithUnit

  value?: ValueWithUnit;
  trend?: TrendWithValue;
  text?: string;

  // GraphChart
  chart: TimeSeriesChart;
  showAxis?: boolean;
  area?: boolean;
};

export type StackedTimeSeriesChartVM = EntityHeader & {
  title: string;
  unit: string;
  currentValue?: number;
  avgValue?: number;
  trend?: TrendWithValue;
  text?: string;

  // GraphChart
  chart: StackedTimeSeriesChart;
};

export type TimeSeriesEntry = {
  timestamp: ClockTimestamp;
  values: (number | 'noValue')[];
};

export type ChartValueLabel = { prefix?: string; suffix?: string; data: string[] };
export type HelpLine = { name: string; value: number; color?: ColorCodes };

type TimeSeriesChart = {
  timeSeries: ClockTimestamp[];
  graph: {
    axis: 'primary' | 'secondary';
    name: string;
    color?: ColorCodes;
    values: (number | 'noValue')[];
    valueLabels?: ChartValueLabel[];
  }[];
  helpLines?: HelpLine[];
};
type StackedTimeSeriesChart = {
  timeSeries: ClockTimestamp[];
  graph: { name: string; color?: ColorCodes; values: (number | 'noValue')[] }[];
  helpLines?: HelpLine[];
};

/** PieChart */
export type PieChartCardVM = EntityHeader & {
  title: string;

  // GraphChart
  chart: PieChart;
};

export type ChartValue = { name: string; value: number; valueLabel?: string; color?: ColorCodes };
export type PieChart = {
  data: ChartValue[];
  unit: string;
  alternativeText?: string; // If set, display alternativeText instead of chart area
};

export type HistogramCardVM = EntityHeader & {
  title: string;

  // GraphChart
  chart: HistogramChart;
};

export type HistogramChart = BasicBarChart;

export type BasicBarChart = {
  data: ChartValue[];
  unit?: string;
  color?: ColorCodes;
  helpLines?: HelpLine[];
};

export enum BarChartOrientation {
  BarsToRight,
  BarsToUp,
}
export type BarChart = BasicBarChart & {
  orientation?: BarChartOrientation;
};

export type BarChartCardVM = EntityHeader & {
  title: string;
  chart: BarChart;
};

export type TableVM = EntityHeader & TableData;

export type StaticCardVM = EntityHeader & {
  currentDayIncluded: boolean;
  title: string;
  description?: string;
};

export type SimpleStaticCardVM = EntityHeader & {
  title?: string;
  description?: string;
};
